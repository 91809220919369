/* Recipes.module.css */

.container {
    padding: 20px;
}

.recipeList {
    list-style-type: none;
    padding: 0;
}

.recipeItem {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
}

.recipeItem h2 {
    margin: 0;
    font-size: 24px;
}

.recipeItem ul {
    list-style-type: none;
    padding: 0;
}

.recipeItem li {
    margin-bottom: 5px;
}

.recipeItem p {
    margin: 5px 0;
}

.recipeItem img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 10px;
}

.recipeItem video {
    width: 100%;
    display: block;
    margin-top: 10px;
}

.recipeItem button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.editButton {
    background-color: #f0ad4e;
    color: white;
}

.deleteButton {
    background-color: #d9534f;
    color: white;
}

.saveButton {
    background-color: #5cb85c;
    color: white;
}

.cancelButton {
    background-color: #5bc0de;
    color: white;
}